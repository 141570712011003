import React from 'react';

import { PortableText } from '@portabletext/react';
import { graphql, useStaticQuery } from 'gatsby';
import { components } from '../../portableText';
import { RawPortableText } from '../../types/types';
import ModalOverlay, { ModalOverlayProps } from './ModalOverlay';
import * as styles from './TermsAndConditionsModal.module.scss';

export interface TermsAndConditionsModalProps extends Omit<ModalOverlayProps, 'children'> {
  className?: string;
}

interface QueryData {
  sanitySettings: {
    termsAndConditions: {
      title: string;
      introText: string;
      _rawText: RawPortableText;
    };
  };
}

const TermsAndConditionsModal = ({
  open,
  onClose,
  className,
  ...restProps
}: TermsAndConditionsModalProps): React.ReactElement => {
  const staticData: QueryData = useStaticQuery(graphql`
    {
      sanitySettings {
        termsAndConditions {
          title
          introText
          _rawText(resolveReferences: { maxDepth: 10 })
        }
      }
    }
  `);

  const { title, introText, _rawText } = staticData.sanitySettings.termsAndConditions;

  return (
    <ModalOverlay
      open={open}
      onClose={onClose}
      {...restProps}
      modalClassName={styles.modal}
      modalContainerClassName={styles.modalContentContainer}
      title={title}
      introText={introText}
    >
      <div className={styles.modalText}>
        <PortableText value={_rawText} components={components} />
      </div>
    </ModalOverlay>
  );
};

export default TermsAndConditionsModal;
