import { graphql } from 'gatsby';
import React from 'react';
import { SanityImageType } from '../../../graphql-fragments/SanityImage';
import { BackgroundColorTheme } from '../../../templates/Page';
import { clsx } from '../../../utils/utils';
import { CommonModuleProps } from '../../ModulesContent';
import Image from '../Image';
import ModuleLayout from '../ModuleLayout';
import * as styles from './BooksModule.module.scss';

export const BooksModuleFragment = graphql`
  fragment BooksModule on SanityGtgvBookBooksModule {
    title
    books {
      book {
        title
        subtitle
        flatMockupCover {
          ...SanityImage
        }
      }
      overrideImage {
        ...SanityImage
      }
      testimonial
      stamp {
        ...SanityImage
      }
    }
  }
`;

export type BooksModuleProps = {
  title: string;
  books: Array<{
    book: {
      title: string;
      subtitle?: string;
      flatMockupCover: SanityImageType;
    };
    overrideImage?: SanityImageType;
    testimonial: string;
    stamp?: SanityImageType;
  }>;
  className?: string;
};

export function getModuleBgColor(props: BooksModuleProps): BackgroundColorTheme {
  /**
   * The purpose of this function is to let other modules know which background color this module has.
   * Knowing this, we can use this function to make decisions about the layout and spacing between modules.
   */
  return 'white';
}

function BooksModule(props: BooksModuleProps & CommonModuleProps): React.ReactElement {
  const { title, books, className, moduleId } = props;

  return (
    <ModuleLayout
      id={moduleId}
      className={clsx(className, styles.container)}
      contentClassName={clsx(styles.contentContainer)}
      currentModuleBgColor={getModuleBgColor(props)}
      title={title}
      centeredTitleOnDesktop
    >
      <div className={clsx(styles.contentWrapper, styles.centerAligned)}>
        <div className={styles.booksContainer}>
          {books.map((book, i) => (
            <div className={styles.bookContainer} key={i}>
              <div className={styles.imageContainer}>
                <Image
                  image={book.overrideImage || book.book.flatMockupCover}
                  className={styles.image}
                />
                {book.stamp && (
                  <div className={clsx(styles.stampContainer, styles.mobile)}>
                    <Image image={book.stamp} className={styles.stamp} />
                  </div>
                )}
              </div>
              <div className={styles.bookTextContainer}>
                <h3 className={styles.bookTitle}>{book.book.title}</h3>
                <h4 className={styles.bookSubtitle}>{book.book.subtitle}</h4>
                <p className={styles.bookDescription}>"{book.testimonial}"</p>
              </div>
              {book.stamp && (
                <div className={clsx(styles.stampContainer, styles.desktop)}>
                  <Image image={book.stamp} className={styles.stamp} />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </ModuleLayout>
  );
}

export default BooksModule;
