import * as Sentry from '@sentry/react';
import { pushFormSubmitEvent, withDataLayer } from './tracking/dataLayer';
import { TrackingData } from './utils/hooks';
import {
  generateUniqueId,
  getLocalStorageMemoizedValue,
  getUtmQueryStringFromUtmParams,
  urlJoin,
  wait,
} from './utils/utils';

export async function sendNetlifyEmail(data: { [key: string]: string }) {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const resp = await fetch('/', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: JSON.stringify(data),
  });
  if (resp.status !== 200) {
    throw new Error('Got response with status code ' + resp.status);
  }
  withDataLayer(pushFormSubmitEvent({ type: 'netlify-email' }));
  return true;
}

export async function sendContactToMailchimp({
  data,
  interests,
  trackingData,
}: {
  data: { [key: string]: string };
  interests: { [key: string]: string };
  trackingData: TrackingData;
}) {
  if (process.env.NODE_ENV === 'development') {
    // Code to test form states (should be commented)
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
  }

  const email = data.EMAIL;

  if (!email) {
    throw new Error(`Missing EMAIL in data: ${JSON.stringify(data)}`);
  }

  delete data.EMAIL;

  const body = {
    EMAIL: email,
    data: {
      ...data,
      U_SOURCE: trackingData.utmSource,
      U_MEDIUM: trackingData.utmMedium,
      U_CAMPAIGN: trackingData.utmCampaign,
      U_TERM: trackingData.utmTerm,
      U_CONTENT: trackingData.utmContent,
      U_DEVICE: trackingData.utmDevice,
      U_TARGET: trackingData.utmTarget,
      SRC_URL: urlJoin(window.location.origin, window.location.pathname),
      REFERRER: trackingData.websiteReferrer,
    },
    interests,
  };

  const resp = await fetch('/.netlify/functions/add-contact-to-mailchimp', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });

  if (resp.status !== 200) {
    throw new Error(
      `Got response with status code ${
        resp.status
      } for add-contact-to-mailchimp with data ${JSON.stringify(body)}`,
    );
  }
  withDataLayer(pushFormSubmitEvent({ type: 'mailchimp-add-contact' }));

  return true;
}

export async function sendContactToKeap({
  name,
  email,
  emailStatus,
  keapTagIdsToAdd,
  optinReason,
  trackingData,
  uniqueId,
  phoneNumber,
  acceptTheTerms,
  joinTheHookPointNewsletter,
}: {
  name: string;
  email: string;
  emailStatus: string;
  keapTagIdsToAdd?: Array<string>;
  optinReason?: string;
  trackingData: TrackingData;
  uniqueId?: string;
  phoneNumber?: string;
  acceptTheTerms?: boolean;
  joinTheHookPointNewsletter?: boolean;
}) {
  console.log('sendContactToKeap', emailStatus);
  const resp = await fetch('/.netlify/functions/add-contact-to-keap-background', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name,
      email,
      emailStatus,
      utm_source: trackingData.utmSource,
      utm_medium: trackingData.utmMedium,
      utm_campaign: trackingData.utmCampaign,
      utm_term: trackingData.utmTerm,
      utm_content: trackingData.utmContent,
      utm_device: trackingData.utmDevice,
      utm_target: trackingData.utmTarget,
      // Keeping this one just for backward compatibility
      trafficSourceUrl: urlJoin(window.location.origin, window.location.pathname),
      sourceUrl: urlJoin(window.location.origin, window.location.pathname),
      referralSource: trackingData.websiteReferrer,
      keapTagIdsToAdd: (keapTagIdsToAdd || []).join(','),
      optinReason,
      uniqueId,
      phoneNumber,
      acceptTheTerms,
      joinTheHookPointNewsletter,
    }),
  });
  if (resp.status !== 200 && resp.status !== 202) {
    throw new Error(
      `Got response with status code ${resp.status} for add-contact-to-keap-background with email ${email}`,
    );
  }
  return true;
}

export async function handleGetGuideToGoingViralSubmit(
  name: string,
  email: string,
  emailStatus: string,
  destinationUrl: string,
  trackingData: TrackingData,
  keapTagIdsToAdd?: Array<string>,
  optinReason?: string,
  phoneNumber?: string,
  acceptTheTerms?: boolean,
  joinTheHookPointNewsletter?: boolean,
): Promise<boolean> {
  console.log('handleGetGuideToGoingViralSubmit', emailStatus);
  window.localStorage.setItem('email', email);
  const uniqueId = getLocalStorageMemoizedValue('randomly_generated_unique_id', generateUniqueId);

  withDataLayer(dataLayer => {
    dataLayer.push({
      event: 'form-submit',
      uniqueId,
    });
    // deprecated event
    dataLayer.push({
      event: 'get-guide-to-going-viral-submit',
      uniqueId,
    });
  });

  // Not awaiting on purpose to speed up submission
  sendContactToKeap({
    name,
    email,
    emailStatus,
    phoneNumber,
    keapTagIdsToAdd,
    optinReason,
    trackingData: {
      ...trackingData,
      utmContent: window.ab_branch ? 'ab_' + window.ab_branch : trackingData.utmContent,
    },
    uniqueId,
    acceptTheTerms,
    joinTheHookPointNewsletter,
  }).catch(err => {
    Sentry.captureException(err);
    console.error(err);
  });

  // Awaiting just a bit to ensure the request to Keap
  // has time to leave the browser before we redirect
  await wait(500);

  const utmQuery = getUtmQueryStringFromUtmParams(
    trackingData.utmSource,
    trackingData.utmMedium,
    trackingData.utmCampaign,
    trackingData.utmTerm,
    trackingData.utmContent,
  );

  window.location.href = urlJoin(destinationUrl, '?', utmQuery);

  return true;
}

type ZeroBounceValidationResult =
  | 'valid'
  | 'invalid'
  | 'catch-all'
  | 'unknown'
  | 'spamtrap'
  | 'abuse'
  | 'do_not_mail';

export async function validateEmailWithZeroBounce(
  email: string,
): Promise<ZeroBounceValidationResult> {
  const resp = await fetch('/.netlify/functions/validate-mail', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
    }),
  });
  if (resp.status !== 200) {
    throw new Error(
      `Got response with status code ${resp.status} for validate-mail with email ${email}`,
    );
  }
  return (await resp.json()) as ZeroBounceValidationResult;
}
